<template>
  <div class="role-mgmt">
    <div class="main-body">
      <el-form
        size="mini"
        :inline="true"
        class="demo-form-inline"
      >
        <el-form-item>
          <el-button
            style="font-size: 18px"
            type="primary"
            @click="handleAdd"
          >新增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="recordList"
        style="width: 100%;margin-bottom: 20px;"
        class="table-custom"
        :cell-style="{ color: '#101010',fontSize: '18px' }"
        row-key="number"
        header-cell-class-name="table-header-cell"
      >
        <el-table-column
          prop="schemeNo"
          label="编号"
        ></el-table-column>
        <el-table-column
          prop="schemeName"
          label="名称"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 特殊处理  代理商（内置 门店（内置 资金方（内置-->
            <el-button
              style="font-size: 18px"
              @click="handleEdit(scope.row, 'edit')"
              type="text"
              size="mini"
            >编辑</el-button>
            <el-button
              style="font-size: 18px"
              @click="handleDelete(scope.row)"
              type="text"
              v-if="scope.row.schemeNo != '1616484298364222065' && scope.row.schemeNo != '1616488386119932333' && scope.row.schemeNo != '1616494738402505500'"
              size="mini"
            >删除</el-button>
            <el-button
              style="font-size: 18px"
              @click="handleSetting(scope.row, 1)"
              type="text"
              size="mini"
            >配置菜单</el-button>
            <el-button
              style="font-size: 18px"
              v-if="true"
              @click="handleSetting(scope.row, 2)"
              type="text"
              size="mini"
            >配置资源</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="opearteType == '1' ? '新增角色' : '编辑角色'"
      :visible.sync="dialogTableVisible"
      width="500px"
      :show-close="false"
    >
      <el-form
        ref="form"
        label-width="80px"
      >
        <el-form-item label="名称">
          <el-input
            v-model="schemeName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >确定</el-button>
          <el-button @click="closeDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="relationDialogVisible"
      width="500px"
      :show-close="false"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(1, 1, 1, 0.8)"
    >
      <div style="margin-bottom: 20px; font-size: 15px; font-weight: bold; color: #3596F7">角色：{{relation.name}}
      </div>
      <el-form
        ref="form"
        :model="relation"
        label-width="120px"
      >
        <div v-if="relation.relatedType == '1'">
          <el-form-item label="选择菜单">
            <el-tree
              :data="menuOptions"
              show-checkbox
              default-expand-all
              node-key="number"
              ref="menuTree"
              highlight-current
              :props="{value: 'number', label: 'name', children: 'subMenuList'}"
            ></el-tree>
          </el-form-item>
        </div>
        <div v-if="relation.relatedType == '2'">
          <el-form-item label="选择服务资源">
            <el-tree
              :data="resourceOptions"
              show-checkbox
              default-expand-all
              node-key="number"
              ref="resourceTree"
              highlight-current
              :props="{value: 'number', label: 'name', children: 'subMenuList'}"
            ></el-tree>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSettingSubmit"
          >确定</el-button>
          <el-button @click="closeDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { 
  getRoleList, 
  createOrUpdateRoleJur, 
  createOrUpdateRoleMenuJur, 
  getAllResourceByNumber,
  getAllMenuByNumber,
  delRole,
  getMenuList,
  getServiceList,
} from "@/api/jurisdiction";
export default {
  data() {
    return {
      headerStyle: {
        color: '#333',
      },
      recordList: [],
      dialogTableVisible: false,
      relationDialogVisible: false,
      opearteType: '',
      settingType: '',
      editRecord: {},
      menuOptions: [],
      resourceOptions: [],
      relation: {},
      loading: false,
      /** edit */
      type: '',
      schemeName: '', // 名称
    }
  },
  mounted() {
    this.queryRecordList()
  },
  methods: {
    setCheckMenus(menuList) {
      let checkedMenuNos = []
      menuList.forEach((checkedMenu) => {
        let notFound = true
        this.menuOptions.forEach((menu) => {
          if (checkedMenu.number == menu.number && !checkedMenu.parentMenuNo) {
            notFound = false
          }
        })
        if (notFound) {
          checkedMenuNos.push(checkedMenu.number)
        }
      })
      this.$refs.menuTree.setCheckedKeys(checkedMenuNos)
    },

    setCheckResources(resources) {
      let checkedResourceNos = []
      resources.forEach((resource) => {
        checkedResourceNos.push(resource.number)
      })
      this.$refs.resourceTree.setCheckedKeys(checkedResourceNos)
    },

    handleCheckAllChange(checked, index) {
      let checkedResources = []
      this.resourceOptions[index].serviceResourceList.forEach((element) => {
        checkedResources.push(element.number)
      })
      this.checkedResources[index] = checked ? checkedResources : []
      this.isIndeterminate[index] = false
    },
    handleCheckedCitiesChange(value, index) {
      let checkedCount = value.length
      let totalLength = this.resourceOptions[index].serviceResourceList.length
      let checkAll = JSON.parse(JSON.stringify(this.checkAll))
      let isIndeterminate = JSON.parse(JSON.stringify(this.isIndeterminate))
      checkAll[index] = checkedCount === totalLength
      isIndeterminate[index] = checkedCount > 0 && checkedCount < totalLength
      this.checkAll = checkAll
      this.isIndeterminate = isIndeterminate
    },
    async queryRecordList() {
      const res = await getRoleList({});
      this.recordList = res.ldata
    },

    handleAdd() {
      this.opearteType = '1'
      this.type = ''
      this.editRecord = {}
      this.schemeName = ''
      this.dialogTableVisible = true
    },

    onSubmit() {
      this.createOrUpdate()
    },
    /** 新增 */
    async createOrUpdate() {
      
      if (!this.schemeName) {
        this.$message.error('请输入姓名！')
        return
      }
      const params = {
        name: this.schemeName
      }
      if (this.opearteType == 2) {
        params.number = this.editRecord.schemeNo
      }
      const res = await createOrUpdateRoleJur(params);
      this.$message.success('操作成功');
      this.closeDialog();
      this.queryRecordList();
    },

    onSettingSubmit() {
      let relatedNos = this.relation.relatedNos
      let relatedNoSet = new Set()
      if (relatedNos) {
        relatedNos.forEach((element) => {
          element.forEach((num, index) => {
            if (index > 0) {
              relatedNoSet.add(num)
            }
          })
        })
      }
      this.relation.relatedNoSet = relatedNoSet
      this.createOrUpdateRelation()
    },

    async createOrUpdateRelation() {
      let relatedNoSet = []
      let relatedType = this.relation.relatedType
      if (relatedType == '1') {
        relatedNoSet = this.$refs.menuTree.getCheckedKeys().concat(this.$refs.menuTree.getHalfCheckedKeys())
      } else {
        relatedNoSet = this.$refs.resourceTree.getCheckedKeys(true)
      }

      let param = {
        authoritySchemeNo: this.relation.schemeNo,
        relatedType: this.relation.relatedType,
        relatedNoSet: relatedNoSet,
      }
      const res  = await createOrUpdateRoleMenuJur(param);
      this.$message.success('操作成功');
      this.closeDialog()
      this.queryRecordList()
    },

    async getAllResourceByNumber(number) {
      this.loading = true
      let param = {
        authoritySchemeNo: number,
      }
      try {
        const res  = await getAllResourceByNumber(param);
        res.ldata.forEach((v) => {
          v.subMenuList = v.serviceResourceList
        })
        this.loading = false
        this.setCheckResources(res.ldata)
      } catch (error) {
        this.loading = false
      }
    },

    async getAllMenuByNumber(number) {
      this.loading = true
      let param = {
        authoritySchemeNo: number,
      }
      try {
        const res = await getAllMenuByNumber(param);
        this.loading = false;
        this.setCheckMenus(res.ldata)
      } catch (error) {
         this.loading = false
      }
    },

    closeDialog() {
      this.dialogTableVisible = false
      this.relationDialogVisible = false
    },
    handleEdit(row) {
      this.opearteType = '2'
      this.dialogTableVisible = true
      this.menuOptions = []
      this.schemeName = row.schemeName
      this.editRecord = row
    },

    handleSetting(row, type) {
      this.loading = true
      this.relationDialogVisible = true
      let relation = row
      /** type 1 配置菜单 2配置权限 */
      relation.relatedType = type
      relation.relatedNos = []
      relation.platformType = '3'
      this.relation = relation
      if (type == 1) {
        this.getAllMenu()
      } else {
        this.getAllResource()
      }
    },

    handleDelete(item) {
      this.$confirm('是否确认删除？').then(() => {
        this.delete(item.schemeNo)
      })
    },

    async delete(number) {
      let params = {
        number: number,
      }
      const res = await delRole(params);
      this.$message.success('操作成功');
      this.queryRecordList()
    },

    async getAllMenu() {
      let params = {
        platformType: this.relation.platformType || '3',
        recursion: true,
      }
      const res = await getMenuList(params);
      this.menuOptions = res.ldata
      this.getAllMenuByNumber(this.relation.schemeNo)
    },

    async getAllResource() {
      const params = {
        findChildren: true,
      }
      const res = await getServiceList(params);
      this.resourceOptions = res.ldata
      this.checkAll = []
      this.isIndeterminate = []
      this.checkedResources = []
      this.resourceOptions.forEach((element) => {
        this.checkAll.push(false)
        this.isIndeterminate.push(true)
        this.checkedResources.push([])
        element.subMenuList = element.serviceResourceList
      })
      this.getAllResourceByNumber(this.relation.schemeNo)
    },

    handleQuery() {
      this.beginIndex = 0
      this.queryRecordList()
    },
    showErrorMessage(message) {
      if (message == '0001') {
        this.$message.warning('登录超时，请重新登录')
      } else if (message == '0004') {
      } else {
        this.$message.error(message)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main-body {
  .title {
    font-size: 20px;
    padding: 30px 0 40px;
  }

  .list {
    line-height: 30px;
    margin: 20px 30px 10px;

    .name-label {
      float: left;
    }

    .value-label {
      display: inline-block;
      height: 30px;
    }

    .date-global {
      width: 185px;
    }

    .list-input {
      width: 180px;
    }

    .fixed-width-100 {
      width: 100px;
    }

    .fixed-width-200 {
      width: 200px;
    }

    .fixed-width-300 {
      width: 300px;
    }

    .fixed-width-400 {
      width: 400px;
    }
  }

  .table-custom {
    border-bottom: 1px solid #cdcdcd;

    .text-btn {
      width: 50px;
      height: 24px;
      line-height: 24px;
      color: #4949ff;
      display: inline-block;
      background-color: #67aff6;
      cursor: pointer;
    }

    .text-btn.disabled {
      border: #67aff6;
      background-color: #fff;
      cursor: default;
    }

    .fixed-width-75 {
      width: 75px;
    }

    .fixed-width-100 {
      width: 100px;
    }

    .fixed-width-300 {
      width: 300px;
    }

    .mt4 {
      margin-top: 4px;
    }
  }
}
</style>

<style>
.main-body .table-header-cell {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}
</style>
